import { useEffect, useState } from 'react';
import { NSTGatewayHeaderNonAuthLink } from 'content/headerContent';
import { AppBar, Box, Button, Container, Toolbar, Tooltip } from '@mui/material';
import { BadgeLoginBlueIcon, BadgeSignUpBlueIcon, IconHamburger, LogoMainNSTGateway } from 'assests/Home';
import HamburgerDrawer from './HamburgerDrawer';
import { Link, NavLink } from 'react-router-dom';
import 'components/Header/Header.css';
import useScrollToTop from 'utils/useScrollToTop';
import SnackbarComponent from 'components/Notification/Snackbar';
import { ISnackBar, ISnackBarStatus } from 'interfaces/notifcation';
import { executeLogout } from 'redux/action/authentication';
import { ILogout } from 'interfaces/authentication';
import { CONSTANT_ROUTES } from 'constants/ConstantRoutes';

const Header = () => {
    const [snackbar, setSnackbar] = useState<ISnackBar>({ open: false, message: '', status: 'success' });
    const [isScrolled, setIsScrolled] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { handleLogoClick } = useScrollToTop();
    const isToken = !!localStorage.getItem('token');
    const toggleDrawer = (value: boolean) => {
        setIsDrawerOpen(value);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 1) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        }
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    const redirectToLoginSite = () => {
        window.open(CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.LOGIN , "_self", "noreferrer");
    };
    const redirectToSignUpSite = () => {
        window.open(CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP, "_self", "noreferrer");
    };

    const handleLogInOut = async () => {
        if (isToken) {
            try {
                const payload: ILogout = {
                    authorizationKey: localStorage.getItem('token') ?? '',
                    email: localStorage.getItem('email') ?? '',
                };
                const response = await executeLogout(payload);
                setSnackbar({
                    open: true,
                    message: 'Logout successful.',
                    status: 'success' as ISnackBarStatus
                });
                if (response.status.toLowerCase() === 'success') {
                    localStorage.removeItem('token');
                    localStorage.removeItem('email');
                    window.open('/', '_self');
                }

            } catch (error: any) {
                setSnackbar({ open: true, message: error.message || 'Login failed.', status: 'error' });
            }
        } else {
            redirectToLoginSite();
        }
    }

    return (
        <AppBar className={`${isScrolled ? "scroll" : ''}`}>
            <Container>
                <Toolbar disableGutters>
                    <Link to='/' onClick={handleLogoClick}>
                        <LogoMainNSTGateway />
                    </Link>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {NSTGatewayHeaderNonAuthLink && (NSTGatewayHeaderNonAuthLink || [])?.map((item) => {
                            return (
                                item.tab === 'login' ? <span  key={item?.href} aria-hidden="true" className={`navlink-header-redirect ${item?.className}`} onClick={handleLogInOut}>{isToken ? 'Logout' : 'Login'} {item.Icon && item?.Icon}</span> :
                                    <NavLink
                                        to={item.href}
                                        key={item?.href}
                                        target='_self'
                                        rel='noopener noreferrer'
                                        className={`navlink-header-redirect ${item?.className}`}
                                    >
                                        {item?.page}
                                        {item.Icon && item?.Icon}
                                    </NavLink>
                            );
                        })}
                    </Box>
                    <div className='nst-header-right'>
                        <div className="nst-gateway-on-page-scalling-auth">
                            <div className="nst-gateway-on-page-scalling-auth-btn-sign-up">
                                <Tooltip title="Sign Up" placement="top" arrow>
                                    <Button onClick={redirectToSignUpSite} startIcon={<BadgeSignUpBlueIcon />} />
                                </Tooltip>
                            </div>
                            <div className="nst-gateway-on-page-scalling-auth-btn-login">
                                <Tooltip title={isToken ? "Logout" : "Login"} placement="top" arrow>
                                    <Button onClick={redirectToLoginSite} startIcon={<BadgeLoginBlueIcon />} />
                                </Tooltip>
                            </div>
                        </div>
                        <div onClick={() => toggleDrawer(true)} className="nst-gateway-header-icon-hamburger" aria-hidden="true">
                            <IconHamburger />
                        </div>
                    </div>
                </Toolbar>
            </Container>
            <HamburgerDrawer
                open={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                handleLogInOut={handleLogInOut}
            />
            {
                snackbar.open &&
                <SnackbarComponent
                    open={snackbar.open}
                    setSnackbar={setSnackbar}
                    status={snackbar.status}
                    message={snackbar.message}
                />
            }
        </AppBar>
    );
}

export default Header;