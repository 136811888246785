import { API_URL } from "constants/Api";
import { ILoginReq, ILogout, ISignupReq } from "interfaces/authentication";
import { ISnackBarStatus } from "interfaces/notifcation";
import { executeApi } from "utils/axios/general";

export const executeSignup = async (payload: ISignupReq) => {
    const signupApiQuery = `senderName=${payload.senderName}&senderEmail=${payload.senderEmail}&senderPIN=${payload.senderPIN}&senderMobileNo=${payload.senderMobileNo}${
        payload.businessFullName ? `&businessFullName=${payload.businessFullName}` : ''
    }`;
    
    const response: { messageLine1: string; messageLine2: string; status: ISnackBarStatus } = await executeApi(
        "get",
        `${API_URL.SIGNUP}?${signupApiQuery}`
    );
    return response;
};

export const executeLogin = async (payload: ILoginReq) => {
    const loginApiQuery = `senderEmail=${payload.email}&senderPIN=${payload.pin}`;
    const response: { messageLine1: string; status: ISnackBarStatus; data: string[] } = await executeApi(
        "get",
        `${API_URL.LOGIN}?${loginApiQuery}`
    );
    return response;
};

export const executeLogout = async (payload: ILogout) => {
    const logoutApiQuery = `authorizationKey=${payload.authorizationKey}&senderEmail=${payload.email}`;
    const response: { messageLine1: string; status: ISnackBarStatus } = await executeApi(
        "get",
        `${API_URL.LOGOUT}?${logoutApiQuery}`
    );
    return response;
};
