// import { BadgeInstagramIcon, BadgeLinkedInIcon, BadgeXTwitterIcon } from "assests/Home"
import { CONSTANT_ROUTES } from "constants/ConstantRoutes";

export const nstGatewayHeading = [
    {
        className: "nst-gateway-heading-navigation",
        heading: "Navigation",
        subSections: [
            {
                key:"0",
                nameLink: "Home",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.HOME
            },
            {
                key:"1",
                nameLink: "Solution",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"2",
                nameLink: "Company",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.COMPANY
            },
        ],
    },
    {
        className: "nst-gateway-heading-support",
        heading: "Support",
        subSections: [
            {
                key:"0",
                nameLink: "Policy",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"1",
                nameLink: "FAQs",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
        ],
    },
    {
        className: "nst-gateway-heading-solutions",
        heading: "Solutions",
        subSections: [
            {
                key:"surcharging",
                nameLink: "Surcharging",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"cash_discount",
                nameLink: "Cash Discount",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"fraud_prevention",
                nameLink: "Fraud Prevention",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"gift_loyalty_points",
                nameLink: "Gift and Loyalty Points",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
            {
                key:"multi_currency",
                nameLink: "Multi-Currency",
                href: CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.MERCHANT_SOLUTION
            },
        ]
    },
]

export const nstSocialMediaRef = [
    // For now please have as it is , while in need we can use these social links
    // {
    //     key: "0", 
    //     link: "#", // this link can be nst media link
    //     mediaIcon: <BadgeLinkedInIcon />
    // },
    // {
    //     key: "1",
    //     link: "#", // this link can be nst media link
    //     mediaIcon: <BadgeInstagramIcon />
    // },
    // {
    //     key: "2",
    //     link: "#", // this link can be nst media link
    //     mediaIcon: <BadgeXTwitterIcon />
    // },
]