import { Button, TextField, IconButton, InputAdornment, CircularProgress, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormEvent, useState } from 'react';
import { IFormData } from 'interfaces/authentication';

interface ISignUpFormProps {
    formData: IFormData;
    formErrors: { [key: string]: string };
    loading: boolean;
    onHandleChange: (value: string, field: keyof IFormData) => void;
    onSubmit: (e: FormEvent) => void;
    onCancel: () => void;
    handlebusinessNamechange: () => void;
    userType : 'Merchant' | 'Consumer';
    setUserType: (value :  'Merchant' | 'Consumer') => void
}

const registerForm = {
    field: {
        name: {
            IField: 'name',
            Lable: 'Full Name',
            Placeholder: 'First Name and Last Name',
            IType: '',
        },
        email: {
            IField: 'email',
            Lable: 'Email',
            Placeholder: 'Email',
            IType: 'email',
        },
        pin: {
            IField: 'pin',
            Lable: 'PIN (Min 8 digits)',
            Placeholder: 'PIN (Min 8 digits)',
            IType: 'password',
        },
        confirmPin: {
            IField: 'confirmPin',
            Lable: 'Confirm PIN',
            Placeholder: 'Confirm PIN (the same as PIN)',
            IType: 'password',
        },
        mobile: {
            IField: 'mobile',
            Lable: 'Mobile Number',
            Placeholder: 'Mobile Number',
            IType: '',
        },
        businessFullName: {
            IField: 'businessFullName',
            Lable: 'Business Full Name',
            Placeholder: 'Business Full Name',
            IType: '',
        },
    },
};

const SignUpForm = ({
    formData,
    formErrors,
    loading,
    onHandleChange,
    onSubmit,
    onCancel,
    userType,
    setUserType,
    handlebusinessNamechange
}: ISignUpFormProps) => {
    const [showPin, setShowPin] = useState(false);
    const [showConfirmPin, setShowConfirmPin] = useState(false);

    const handleOnChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        isNumberOnly: boolean = false
    ) => {
        const { name, value } = e.target;
        const newValue = isNumberOnly ? value.replace(/\D/g, '') : value;
        onHandleChange(newValue, name as keyof IFormData);
    };

    const togglePinVisibility = () => setShowPin((prev) => !prev);
    const toggleConfirmPinVisibility = () => setShowConfirmPin((prev) => !prev);

    return (
        <form onSubmit={onSubmit} noValidate autoComplete="off">
            <div className='form-group'>
                <FormLabel component="legend">Signup as</FormLabel>
                <RadioGroup
                    row
                    value={userType}
                    onChange={(e) => {setUserType(e.target.value as 'Merchant' | 'Consumer'); handlebusinessNamechange();}}
                >
                    <FormControlLabel value="Merchant" control={<Radio />} label="Merchant" />
                    <FormControlLabel value="Consumer" control={<Radio />} label="Consumer" />
                </RadioGroup>
            </div>

            <div className='form-group'>
                <label htmlFor="name">
                    {registerForm.field.name.Lable}
                    <span style={{ color: '#FF3B3B' }}>*</span>
                </label>
                <TextField
                    fullWidth
                    name={registerForm.field.name.IField}
                    value={formData.name}
                    onChange={handleOnChange}
                    inputProps={{ pattern: '[A-Za-z ]+' }}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    autoComplete="off"
                />
            </div>
            {userType === 'Merchant' && (
                <div className='form-group'>
                    <label htmlFor="organization">
                        {registerForm.field.businessFullName.Lable}
                        <span style={{ color: '#FF3B3B' }}>*</span>
                    </label>
                    <TextField
                        fullWidth
                        name={registerForm.field.businessFullName.IField}
                        value={formData.businessFullName}
                        onChange={handleOnChange}
                        error={!!formErrors.businessFullName}
                        helperText={formErrors.businessFullName}
                        autoComplete="off"
                    />
                </div>
            )}
            <div className='form-group'>
                <label htmlFor="email">
                    {registerForm.field.email.Lable}
                    <span style={{ color: '#FF3B3B' }}>*</span>
                </label>
                <TextField
                    fullWidth
                    name={registerForm.field.email.IField}
                    value={formData.email}
                    onChange={handleOnChange}
                    inputProps={{ type: registerForm.field.email.IType }}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    autoComplete="off"
                />
            </div>
            <div className='form-group'>
                <label htmlFor="password">
                    {registerForm.field.pin.Lable}
                    <span style={{ color: '#FF3B3B' }}>*</span>
                </label>
                <TextField
                    fullWidth
                    name={registerForm.field.pin.IField}
                    type={showPin ? 'text' : 'password'}
                    value={formData.pin}
                    inputProps={{ inputMode: 'numeric' }}
                    onChange={(e) => handleOnChange(e, true)}
                    error={!!formErrors.pin}
                    helperText={formErrors.pin}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={togglePinVisibility}>
                                    {showPin ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="password">
                    {registerForm.field.confirmPin.Lable}
                    <span style={{ color: '#FF3B3B' }}>*</span>
                </label>
                <TextField
                    fullWidth
                    name={registerForm.field.confirmPin.IField}
                    type={showConfirmPin ? 'text' : 'password'}
                    value={formData.confirmPin}
                    inputProps={{ inputMode: 'numeric' }}
                    onChange={(e) => handleOnChange(e, true)}
                    error={!!formErrors.confirmPin}
                    helperText={formErrors.confirmPin}
                    autoComplete="new-password"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleConfirmPinVisibility}>
                                    {showConfirmPin ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className='form-group'>
                <label htmlFor="mobile">
                    {registerForm.field.mobile.Lable}
                    <span style={{ color: '#FF3B3B' }}>*</span>
                </label>
                <TextField
                    fullWidth
                    name="mobile"
                    value={formData.mobile}
                    onChange={(e) => handleOnChange(e, true)}
                    inputProps={{ inputMode: 'numeric' }}
                    error={!!formErrors.mobile}
                    helperText={formErrors.mobile}
                    autoComplete="off"
                />
            </div>
            <div className="nst_gateway_signupform_content_bottom">
                <div className="nst_gateway_signupform_handle_button">
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={loading}
                        startIcon={loading ? <CircularProgress color="inherit" size={20} /> : null}
                        style={{ opacity: loading ? 0.8 : 1 }}
                    >
                        Signup
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        color="secondary"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </form>
    );
};

export default SignUpForm;
