import { ArrowIcon, CallIcon, HomeSectionBackSvgImg } from 'assests/Home';
import { Link } from 'react-router-dom';
import HeroImg from "../../../assests/Home/hero-img.png";
import { CONSTANT_ROUTES } from 'constants/ConstantRoutes';

export const SectionMain = () => {
    return (
        <div className='container'>
            <div className='nst-home-hero-sec'>
                <div className='nst-hero-left'>
                    <h1 className='main-head-blue'>
                    Innovative Payment System based on Bankcards, <br></br>
                    Bank Accounts (ACH) and Digital Reward Points
                        {/* Innovative Payment System
                        <br />
                        based on Bankcards, Bank and Digital Loyalty Points */}
                    </h1>

                    <p>
                        Flexible and secure payment solutions that provide all financial and business services you need to run your business with ease: payments, management of orders, accounting, and reporting.
                    </p>
                    <div className="get-started-section">
                        <div className="get-started-btn-section">
                            <Link className='get-started-btn' to={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP}>
                                <span className='txt-btn'>Get Started</span>
                                <span className='MuiButton-endIcon'><ArrowIcon /></span>
                            </Link>
                        </div>
                        <div className="phone-number-section">
                            <a href="tel:+18006832977" className="phone-number-link" >
                                <CallIcon /><span>(800) 683-2977</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="nst-hero-right">
                    <div className="front-view-main-source">
                        <img src={HeroImg} alt="meeting_person" />
                    </div>
                    <div className="back-view-source">
                        <HomeSectionBackSvgImg />
                    </div>
                </div>
            </div>
        </div>
    )
}
