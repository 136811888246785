import SnackbarComponent from "components/Notification/Snackbar";
import { ILoginReq } from "interfaces/authentication";
import { ISnackBar, ISnackBarStatus } from "interfaces/notifcation";
import { FormEvent, useState } from "react";
import { executeLogin } from "redux/action/authentication";
import { hashPassword } from "utils/passwordHashing";
import { validateInput, ValidationResult } from "utils/validationUtils";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import { CONSTANT_ROUTES } from "constants/ConstantRoutes";

const Login = () => {
    const [formData, setFormData] = useState<ILoginReq>({
        email: "",
        pin: "",
    });
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
    const [snackbar, setSnackbar] = useState<ISnackBar>({ open: false, message: '', status: 'success' });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (value: string, field: keyof ILoginReq): void => {
        const tempFormData = { ...formData, [field]: value };
        const updatedErrors = { ...formErrors };
        const validationResult: ValidationResult = validateInput(value, field, tempFormData);
        if (!validationResult.isValid) {
            updatedErrors[field] = validationResult.errorMessage;
        } else {
            delete updatedErrors[field];
        }
        setFormData(tempFormData);
        setFormErrors(updatedErrors);
    };


    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const validationErrors: { [key: string]: string } = {};
        for (const field in formData) {
            const result = validateInput(formData[field as keyof ILoginReq], field, formData);
            if (!result.isValid) {
                validationErrors[field] = result.errorMessage;
            }
        }
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length > 0) return;
        try {
            const hashedPassword = await hashPassword(formData.pin);
            if (!hashedPassword) {
                return setSnackbar({ open: true, message: 'Error while hashing password.', status: 'error' });
            }
            const payload: ILoginReq = {
                email: formData.email,
                pin: hashedPassword,
            };
            setLoading(true);
            const response = await executeLogin(payload);
            setSnackbar({
                open: true,
                message: response.messageLine1,
                status: response.status.toLowerCase() as ISnackBarStatus
            });
            if (response.status.toLowerCase() === 'success') {
                response.data.length > 1 && localStorage.setItem('token', response.data[1]);
                response.data.length > 1 && localStorage.setItem('email', response.data.length > 3 ? response.data[3] : formData.email);
                window.open('/', '_self');
                setLoading(true);
                handleCancel();
            }

        } catch (error: any) {
            setLoading(false);
            setSnackbar({ open: true, message: error.message || 'Login failed.', status: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setLoading(false);
        setFormData({
            email: "",
            pin: ""
        });
        setFormErrors({});
        navigate(CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.NAVIGATION.HOME);
    };

    return (
        <>
            <div className='nst-gate-page'>
                <div className="top signup-content">
                    <div className="content ">
                        {/* <h1>Fill out the form below and we will contact you shortly!</h1>
                        <p>Our flexible and secure payments platform and dedicated support makes it easy to accept payments anytime, anywhere.</p> */}
                    </div>
                </div>
                <div className="signup-bottom">
                    <div className="bottom">
                        <section className="signup_sec comm_auth_sec">
                            <div className="nst_gateway_signup_content_head">
                                <h2 className="nst_gateway_signup_header_title">Login</h2>
                            </div>
                            <div className="nst_gateway_signup_form_wrapper">
                                <LoginForm
                                    formData={formData}
                                    formErrors={formErrors}
                                    loading={loading}
                                    onHandleChange={handleChange}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                />
                            </div>

                        </section>
                    </div>
                </div>
            </div>
            {
                snackbar.open &&
                <SnackbarComponent
                    open={snackbar.open}
                    setSnackbar={setSnackbar}
                    status={snackbar.status}
                    message={snackbar.message}
                />
            }
        </>
    )
}

export default Login
