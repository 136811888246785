import { IFormData, ILoginReq } from "interfaces/authentication";

export interface ValidationResult {
    isValid: boolean;
    errorMessage: string;
}

const errorMessages = {
    required: (field: string) => `${field} is required.`,
    invalidEmail: "Please enter a valid email address.",
    pinLength: "PIN must be at least 8 digits long.",
    pinMismatch: "The PINs do not match.",
    enterPinFirst: "Please enter your PIN first.",
};

const emailRegex = /^[a-zA-Z0-9](\.?[a-zA-Z0-9_-]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const isEmpty = (value: string) => !value.trim();

export const validateInput = (
    value: string,
    field: string,
    formData?: IFormData | ILoginReq
): ValidationResult => {
    let errorMessage = "";

    switch (field) {
        case "name":
            if (isEmpty(value)) {
                errorMessage = errorMessages.required("Name");
            }
            break;

        case "email":
            if (isEmpty(value)) {
                errorMessage = errorMessages.required("Email");
            } else if (!emailRegex.test(value)) {
                errorMessage = errorMessages.invalidEmail;
            }
            break;

        case "pin":
            if (isEmpty(value)) {
                errorMessage = errorMessages.required("PIN");
            } else if (!/^\d{8,}$/.test(value)) {
                errorMessage = errorMessages.pinLength;
            }
            break;

        case "confirmPin":
            if (!formData?.pin) {
                errorMessage = errorMessages.enterPinFirst;
            } else if (value !== formData.pin) {
                errorMessage = errorMessages.pinMismatch;
            }
            break;

        case "mobile":
            if (isEmpty(value)) {
                errorMessage = errorMessages.required("Mobile number");
            }
            break;

        case "businessFullName":
            if (isEmpty(value)) {
                errorMessage = errorMessages.required("Business Full Name");
            }
            break;

        default:
            break;
    }

    return {
        isValid: !errorMessage,
        errorMessage,
    };
};
