import { ArrowIcon } from 'assests/Home';
import PayMentBusinessGrowChartImg from 'assests/Home/integrated_img.png';
import { subSectionsContentPayment } from 'content/Home/sectionPayment';
import { Link } from 'react-router-dom';
import ContentSubSection from './ContentSubSection';
import { CONSTANT_ROUTES } from 'constants/ConstantRoutes';

const SectionPayment = () => {
    return (
        <section className="payment-main-container">
            <div className='container'>
                <div className='payment-main-wrap'>
                    <div className='payment-top'>
                        <div className="payment-content">
                            <div className="payment-lable-pannel">
                                <p>One Partner, Endless Opportunities</p>
                            </div>
                            <div className="payment-heading">
                                <h2>Integrated payment solutions to grow your business</h2>
                            </div>
                        </div>
                        <div className="payment-grow-business-chart-img-right">
                            <img src={PayMentBusinessGrowChartImg} alt='growchart' />
                        </div>
                    </div>
                    <div className='payment-bottom'>
                        <div className="payment-business-card">
                            {subSectionsContentPayment && (subSectionsContentPayment || [])?.map((card, index) => (
                                <ContentSubSection
                                    key={card.headingTitle}
                                    wrapperId={card.wrapper}
                                    FrameIcon={card.frameIcon}
                                    headingTitle={card.headingTitle}
                                    content={card.content}
                                    useClassName={card.className}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="payment-navigation-btn">
                        <div className="get-started-btn-section" >
                            <Link className='get-started-btn' to={CONSTANT_ROUTES.NST_GATEWAY_APP_MAIN.ROUTE_UNAUTH.USER.SIGNUP}> <span className='txt-btn'>Get Started</span><span className='MuiButton-endIcon'>
                                <ArrowIcon /></span> </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionPayment