import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

interface StoresCardsProps {
    cardStoredId: string;
    mainWrapperHeaderBlue: string;
    srcImgMain: string;
    imgAltText: string;
    srcImgSmall?: string;
    storeHeaderTitle: string;
    paraContent: string;
    icon1: JSX.Element;
    textOnButton01?: string;
    icon2?: JSX.Element;
    textOnButton02?: string;
    useClassName: string;
    useCardContentBtnClassName: string;
    useCardImgClassName: string;
    btn01Href: string;
    btn02Href: string;
}


const StoresCards = ({ btn01Href, btn02Href, cardStoredId, mainWrapperHeaderBlue, srcImgMain, srcImgSmall, imgAltText, storeHeaderTitle, paraContent, icon1, textOnButton01, icon2, textOnButton02, useClassName, useCardContentBtnClassName, useCardImgClassName }: StoresCardsProps) => {
    return (
        <div className="store-card-main-container" id={cardStoredId || undefined}>
            <div className={`sec-payments-card-main ${useClassName}`}>
                <div className="sec-payments-lable-main">
                    <h2>{mainWrapperHeaderBlue}</h2>
                </div>
                <div className="sec-payments-card-alpha">
                    <div className={`sec-payments-card-content-btn ${useCardContentBtnClassName}`}>
                        <div className="sec-payments-card-cntn">
                            <div className="sec-payments-card-cntn-heading">
                                <h3>{storeHeaderTitle}</h3>
                            </div>
                            <div className="sec-payments-card-cntn-explore-text">
                                <p>{paraContent}</p>
                            </div>
                        </div>
                        <div className="sec-payments-card-btn">
                            {textOnButton01?.trim()?.length !== 0 ? (
                            <div
                                className="store-card-btn"
                            >
                                    <Tooltip title="Downlaod The File" placement='right-start'>
                                        <Link
                                            className='get-started-btn'
                                            target='_blank'
                                            to={btn01Href} download> <span className='txt-btn'>{textOnButton01}</span><span className='MuiButton-endIcon'>
                                                {icon1}</span> </Link>
                                    </Tooltip>
                            </div>
                            ) : null}
                            {icon2 ? (
                                <div className="store-card-btn">
                                    <Link className='get-started-btn Mui-disabled disabled-link' target='_blank' to={btn02Href}> <span className='txt-btn'>{textOnButton02}</span><span className='MuiButton-endIcon'>
                                        {icon2}</span> </Link>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className={`sec-payments-card-image-preview-beta`}>
                    <div className={`sec-payments-card-img-main ${useCardImgClassName}`}>
                        <div className='sec-payment-big-img'>
                            <img src={srcImgMain} alt={imgAltText} />
                        </div>
                        {srcImgSmall ? (
                            <div className='sec-payment-small-img'>
                                <img src={srcImgSmall} alt='shopify img' />
                            </div>
                        ) : null}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StoresCards;